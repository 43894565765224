import React, { createContext, useContext } from 'react'
import { FC } from '~/types'
import getContentArray from './utils/getContentArray'
import isRecommended from './utils/isRecommended'
import getFilteredBody from './utils/getFilteredBody'
import getInArticleProducts from './utils/getInArticleProducts'
import isPremium from './utils/isPremium'
import isInfiniteScroll from './utils/isInfiniteScroll'
import { AdManagerContext } from '~/providers/AdManager'

export const ContentContext = createContext({} as any)

type ContentProviderProps = any

export const ContentProvider: FC<ContentProviderProps> = ({
  article,
  domain,
  configuration,
  jsGlobals,
  url,
  children,
}) => {
  const adConfig = useContext(AdManagerContext)

  let value: any = {
    filteredBody: getFilteredBody(article.body),
    isRecommended: isRecommended(article.path),
  }

  value = {
    ...value,
    inArticleProducts: getInArticleProducts(value.filteredBody),
    contentArray: getContentArray({
      articleBody: value.filteredBody,
      article,
      adConfig,
      configuration,
      isRecommended: value.isRecommended,
      isPremium: isPremium(article.premium, domain),
      isInfiniteScroll: isInfiniteScroll(url),
      jsGlobals,
    }),
    hasYW: jsGlobals?.hasYW,
  }

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  )
}
