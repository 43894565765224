import { device } from '~/config/theme/JPIMStyles'
import { MobileBlob, DesktopBlob } from './utils/blobs'
import { withRequest } from '@brightsites/flow-core/lib/providers/RequestProvider'
import styled from 'styled-components'
import { getDestinationWithUtm } from './utils/get-your-world-url'

const YourWorldBanner = ({ sections }: { sections: { name: string }[] }) => (
  <Wrapper id="yw-banner">
    <div>
      <Header data-testid="yw-banner-header">
        Join YourWorld to share your stories
      </Header>
      <Paragraph data-testid="yw-banner-paragraph">
        Sign up now and see them on Sussex World!
      </Paragraph>
    </div>
    <Link
      id="yw-banner-link"
      data-testid="yw-banner-link"
      href={getDestinationWithUtm(sections)}
      target="_blank"
    >
      Join YourWorld
    </Link>
  </Wrapper>
)

const Wrapper = styled.div`
  background-color: #f9f5ed;
  background-image: url('data:image/svg+xml,${encodeURIComponent(MobileBlob)}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamily.gabarito};
  gap: 8px;
  margin: 10px 20px 18px;
  max-width: 100%;
  padding: 16px 24px;

  @media ${device.tablet} {
    align-items: center;
    background-image: url('data:image/svg+xml,${encodeURIComponent(
      DesktopBlob,
    )}');
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 0 18px;
  }
`

const Header = styled.h2`
  color: #222;
  font-size: 18px;
  font-weight: 700;
  margin: 0px 0px 8px;
`

const Paragraph = styled.p`
  color: var(--grey-700);
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
`

const Link = styled.a`
  background-color: #e63947;
  border-radius: 9px;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  max-width: 162px;
  padding: 6px 16px;
  text-align: center;
  text-decoration: none;
  z-index: 1;
`

export default withRequest(YourWorldBanner)
