type LocationUrlMap = {
  [key: string]: string
}

const locationUrlMap: LocationUrlMap = {
  'Bexhill and Battle': 'https://yourworld.net/sussex/east-sussex/bexhill/',
  'Brighton and Hove': 'https://yourworld.net/sussex/east-sussex/brighton/',
  Eastbourne: 'https://yourworld.net/sussex/east-sussex/eastbourne/',
  'Hastings and Rye': 'https://yourworld.net/sussex/east-sussex/hastings/',
  Lewes: 'https://yourworld.net/sussex/east-sussex/lewes/',
  Rother: 'https://yourworld.net/sussex/east-sussex/rother/',
  Wealden: 'https://yourworld.net/sussex/east-sussex/wealden/',
  Adur: 'https://yourworld.net/sussex/west-sussex/adur/',
  Arun: 'https://yourworld.net/sussex/west-sussex/arun/',
  'Bognor Regis': 'https://yourworld.net/sussex/west-sussex/bognor-regis/',
  Chichester: 'https://yourworld.net/sussex/west-sussex/chichester/',
  Crawley: 'https://yourworld.net/sussex/west-sussex/crawley/',
  Horsham: 'https://yourworld.net/sussex/west-sussex/horsham/',
  Littlehampton: 'https://yourworld.net/sussex/west-sussex/littlehampton/',
  'Mid Sussex': 'https://yourworld.net/sussex/west-sussex/mid-sussex/',
  'Midhurst and Petworth': 'https://yourworld.net/sussex/west-sussex/petworth/',
  Worthing: 'https://yourworld.net/sussex/west-sussex/worthing/',
}

export default locationUrlMap
