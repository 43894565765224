import locationUrlMap from './location-url-map'

const destinationURL = (sections: { name: string }[]) => {
  const matchingSection = sections.find(
    (section) => section.name in locationUrlMap,
  )
  return matchingSection ? locationUrlMap[matchingSection.name] : '#'
}

const getDestinationWithUtm = (sections: { name: string }[]): string => {
  const baseURL = destinationURL(sections)

  if (baseURL === '#') return baseURL

  const utmParams = new URLSearchParams({
    utm_campaign: 'yourworld_read',
    utm_source: 'sussex_world',
    utm_medium: 'cta_click',
    utm_content: 'article',
  })

  return `${baseURL}?${utmParams.toString()}`
}

export { destinationURL, getDestinationWithUtm }
