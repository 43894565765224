import { Author } from '~/types'

export const getAuthorName = (
  author?: Author | null,
  cleanupFormatting: boolean = true,
): string | null => {
  if (!author) return null

  const fullName =
    author.firstName && author.lastName
      ? `${author.firstName} ${author.lastName}`
      : author.name || null

  if (!fullName) return null

  // Cleans up the author formatting
  if (cleanupFormatting) {
    return fullName
      .replace(/^by\s*/i, '')
      .replace(/,\s*contributor$/i, '')
      .trim()
  }

  return fullName
}

export default getAuthorName
