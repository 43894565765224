/* globals JSGlobals */
export const initSourcePointModals = (): void => {
  const privacyId =
    JSGlobals.mode === 'development'
      ? 166634
      : JSGlobals.sourcePointPrivacyManagerIdV2

  document.querySelectorAll('a.cookie-settings').forEach((el) => {
    el.addEventListener('click', () => {
      if (window._sp_?.gdpr?.loadPrivacyManagerModal) {
        window._sp_.gdpr.loadPrivacyManagerModal(privacyId)
      }
    })
  })
}
