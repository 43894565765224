import styled from 'styled-components'
import { device } from '~/config/theme/JPIMStyles'
import { Author } from '~/types'
import getAuthorName from '../util/get-author-name'

const AuthorWrapper = styled.div<{ showingJobTitle: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: ${({ showingJobTitle }) =>
    showingJobTitle ? 'center' : 'flex-start'};

  @media ${device.laptop} {
    justify-content: center;
  }
`

const AuthorJobTitle = styled.p`
  margin: 0;
  color: #686868;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
`

export const Authors = ({ authors }: { authors: Author[] }) => {
  if (authors.length === 0) return null
  const jobTitle = authors[0]?.jobTitle
  const shouldShowJobTitle = !!jobTitle && authors.length === 1

  return (
    <AuthorWrapper showingJobTitle={shouldShowJobTitle}>
      <span>
        {'By '}
        {authors.map((author, i) => {
          const authorName = getAuthorName(author)
          return (
            <span key={author.id} data-testid="article-author">
              {i > 0 && ', '}
              {author.path ? (
                <a href={author.path}>{authorName}</a>
              ) : (
                authorName
              )}
            </span>
          )
        })}
      </span>
      {shouldShowJobTitle && (
        <AuthorJobTitle className="article-author-job-title">
          {jobTitle}
        </AuthorJobTitle>
      )}
    </AuthorWrapper>
  )
}
