import React, { useContext } from 'react'
import { ArticleData, Configuration, FC, ArticleSnippetData } from '~/types'
import PolarImage from '~/library/ThirdParty/PolarImage'
import Dailymotion from './Embeds/Dailymotion'
import Youtube from './Embeds/Youtube'
import Image from './Image'
import ShotsTvBanner from './Embeds/ShotsTvBanner'
import YourWorldBanner from './Embeds/YourWorldBanner/YourWorldBanner'
import { destinationURL } from './Embeds/YourWorldBanner/utils/get-your-world-url'
import { ContentContext } from '~/providers/Content'

export type HeroProps = {
  theme?: string
  configuration: Configuration
  className: string
  article: ArticleData
  showCaptionOverlay?: boolean
}

const Hero: FC<HeroProps> = ({
  theme = 'default',
  configuration,
  article,
  className,
  showCaptionOverlay = false,
}) => {
  const {
    hero,
    photoArticle: isPhotoArticle,
    pagination: { currentPage } = {},
    sections,
  } = article

  const heroes = hero ?? []
  const imageRatio = 'threebytwo'
  const imageWidths = [320, 640, 990]

  const priorityList: Array<ArticleSnippetData['type']> = [
    'dailymotion',
    'youtube',
    'video-youtube',
    'gallery',
    'image',
    'polar',
  ]

  const prioritisedHeroes = heroes
    .filter((e) => priorityList.includes(e.type))
    .sort((a, b) => priorityList.indexOf(a.type) - priorityList.indexOf(b.type))

  const heroProvider = (hero?: ArticleSnippetData) => {
    if (!hero) return null

    const isTVLinkDisabled = ['scotsman.com', 'yorkshirepost.co.uk'].includes(
      configuration.domain,
    )

    //temporarily hide 'Your World' Banner behind ?YW query param
    const { hasYW } = useContext(ContentContext)

    const shouldDisplayBanner = !isTVLinkDisabled && !isPhotoArticle

    const isDestinationUrlEmpty = destinationURL(sections) === '#'

    const isSussexExpress = configuration.domain === 'sussexexpress.co.uk'

    const shouldDisplayShotsTvBanner =
      shouldDisplayBanner &&
      (isDestinationUrlEmpty || !isSussexExpress || (isSussexExpress && !hasYW))

    const shouldDisplayYourWorldBanner =
      shouldDisplayBanner && isSussexExpress && !isDestinationUrlEmpty && hasYW

    switch (hero.type) {
      case 'dailymotion': {
        if (!isPhotoArticle || currentPage === 1) {
          return (
            <>
              <Dailymotion
                data={hero.data}
                configuration={configuration}
                article={article}
                heroPosition={true}
              />
              {shouldDisplayYourWorldBanner && (
                <YourWorldBanner sections={sections} />
              )}
              {shouldDisplayShotsTvBanner && <ShotsTvBanner />}
            </>
          )
        }
        const firstHeroImage =
          prioritisedHeroes.find((hero) => hero.type === 'gallery')?.data
            ?.gallery[0]?.data ?? null
        return (
          firstHeroImage && (
            <Image
              isHero
              showCaptionOverlay={showCaptionOverlay}
              data={firstHeroImage}
              srcSet={imageWidths}
              ratio={imageRatio}
              theme={theme}
              className={className}
            />
          )
        )
      }
      case 'youtube':
      case 'video-youtube':
        return <Youtube data={hero.data} />
      case 'gallery': {
        const firstGalleryImage = hero.data.gallery[0]?.data ?? null
        return (
          firstGalleryImage && (
            <Image
              isHero
              showCaptionOverlay={showCaptionOverlay}
              data={firstGalleryImage}
              srcSet={imageWidths}
              ratio={imageRatio}
              theme={theme}
              className={className}
            />
          )
        )
      }
      case 'image':
        return (
          <Image
            isHero
            showCaptionOverlay={showCaptionOverlay}
            data={hero.data}
            srcSet={imageWidths}
            ratio={imageRatio}
            theme={theme}
            className={className}
          />
        )
      case 'polar':
        return <PolarImage />
      default:
        return null
    }
  }

  return heroProvider(prioritisedHeroes[0])
}

export default Hero
