import { getDataLayer } from '~/util/dataLayer'
import { gtagConfig } from './gtagConfig'
import { lpsos } from './lpsos'

const analytics = (jsGlobals) => {
  const { ga, domain } = jsGlobals
  const uaConfig = {}
  uaConfig[ga] = { groups: 'default' }

  let GaData = getDataLayer(jsGlobals)

  if (
    !/hub|category|contact-us|home|puzzles|topic|author|partner|video/.test(
      jsGlobals.pageType,
    ) &&
    jsGlobals.pageId
  ) {
    GaData = getDataLayer(jsGlobals, jsGlobals.article)

    let emptyNodes = null
    if (GaData.countOfNodes > 1) {
      emptyNodes = ''
      for (let i = 1; i < GaData.countOfNodes; i++) {
        emptyNodes += '|'
      }
    }

    ;[
      'siteSectionOne',
      'siteSectionTwo',
      'siteSectionThree',
      'siteSectionFour',
      'siteSectionFive',
      'siteSectionSix',
    ].forEach((key, index) => {
      if (typeof jsGlobals.article.siteSections[index] !== 'undefined') {
        if (
          jsGlobals.article.siteSections[index].find((item) => !!item.trim())
        ) {
          GaData[key] = jsGlobals.article.siteSections[index].join('|').trim()
        } else {
          GaData[key] = emptyNodes
        }
      } else {
        GaData[key] = emptyNodes
      }
    })
  }

  let path = ''
  let siteSectionName = ''
  if (jsGlobals.article) {
    if (jsGlobals.article.hierarchy.length >= 2) {
      path = jsGlobals.article.hierarchy[1].path
    } else if (jsGlobals.article.hierarchy.length === 1) {
      path = jsGlobals.article.hierarchy[0].path
    }
  }
  if (path.length > 0) {
    siteSectionName = lpsos[path.replace(/^\//, '')]
  }

  const anaylticConfig = {
    parsely: {
      config: { type: 'parsely' },
      data: { vars: { apikey: domain } },
    },
    gtag: {
      config: {
        type: 'gtag',
        'data-credentials': 'include',
      },
      data: gtagConfig(GaData),
    },
    lpsos: {
      config: {
        config: `https://uk-script.dotmetrics.net/AmpConfig.json?dom=AMPDOC_HOST&tag=${siteSectionName}`,
      },
    },
  }

  return anaylticConfig
}

const sizeOfArticleMPU = 10
const sidebarSlot = [1, 2, 5]

const articleSlots = (size) =>
  Array.apply(null, { length: size })
    .map(Number.call, Number)
    .map((n) => n + 1)

const adConfig = {
  sidebarSlot: sidebarSlot,
  articleSlot: articleSlots(sizeOfArticleMPU).filter(
    (n) => !sidebarSlot.includes(n),
  ),
}

const breakingNewsTickerBlacklistedDomains = [
  '3addedminutes.com',
  'derryjournal.com',
  'farminglife.com',
  'scotsman.com',
  'totallysnookered.com',
  'manchesterworld.uk',
  'liverpoolworld.uk',
]

const breakingNewsTickerBlacklistedPages = [
  '/about',
  '/about/publisher-guidelines',
  '/campaign',
  '/competition',
  '/contact-us',
  '/corporate',
  '/easter',
  '/events',
  '/fathers-day',
  '/group',
  '/halloween',
  '/jobs',
  '/announcements',
  '/celebrations',
  '/members',
  '/mothering-sunday',
  '/national-puzzle-day',
  '/newsletter',
  '/newsletter/premium',
  '/newsletter/premium/thank-you',
  '/newsletter/premium/cancel',
  '/newsletter/premium/cancel/confirmation',
  '/newsletter/premium/faq',
  '/newsletter/premium/upgrade',
  '/newsletter/premium/upgrade/confirmation',
  '/new-year',
  '/partner',
  '/profile',
  '/profile/my-newsletters',
  '/promotion',
  '/puzzles',
  '/register',
  '/forgotten-password',
  '/reset-password',
  '/sign-in',
  '/sports-promotion',
  '/students',
  '/subscribers',
  '/subscriptions',
  '/subscriptions/autumn-sale',
  '/subscriptions/campaign',
  '/subscriptions/competition',
  '/subscriptions/corporate',
  '/subscriptions/easter',
  '/subscriptions/events',
  '/subscriptions/fathers-day',
  '/subscriptions/frequently-asked-questions',
  '/subscriptions/gift-redemption',
  '/subscriptions/group',
  '/subscriptions/halloween',
  '/subscriptions/mothering-sunday',
  '/subscriptions/national-puzzle-day',
  '/subscriptions/new-year',
  '/subscriptions/promotion',
  '/subscriptions/sports',
  '/subscriptions/sports/gift-redemption',
  '/subscriptions/sports-promotion',
  '/subscriptions/spring-sale',
  '/subscriptions/summer-sale',
  '/subscriptions/summer-sports',
  '/subscriptions/students',
  '/subscriptions/terms-and-conditions',
  '/subscriptions/upgrade',
  '/subscriptions/upgrade-offer',
  '/subscriptions/upgrade-promotion',
  '/subscriptions/upgrade-renewal',
  '/subscriptions/upgrade-students',
  '/subscriptions/upgrade-winback',
  '/subscriptions/valentines-day',
  '/subscriptions/winter-sale',
  '/subscriptions/why-register',
  '/subscriptions/xmas',
  '/subscriptions/6-nations',
  '/summer-sports',
  '/upgrade',
  '/upgrade-offer',
  '/upgrade-promotion',
  '/upgrade-renewal',
  '/upgrade-students',
  '/upgrade-winback',
  '/valentines-day',
  '/xmas',
  '/6-nations',
]

const subscriptionPages = [
  '/subscriptions',
  '/subscriptions/help',
  '/subscriptions/sports',
  '/subscriptions/renewal',
  '/subscription-renewal',
  '/campaign',
  '/subscriptions/campaign',
  '/promotion',
  '/subscriptions/promotion',
  '/sports-promotion',
  '/subscriptions/sports-promotion',
  '/new-year',
  '/subscriptions/new-year',
  '/6-nations',
  '/subscriptions/6-nations',
  '/national-puzzle-day',
  '/subscriptions/national-puzzle-day',
  '/valentines-day',
  '/subscriptions/valentines-day',
  '/mothering-sunday',
  '/subscriptions/mothering-sunday',
  '/fathers-day',
  '/subscriptions/fathers-day',
  '/summer-sports',
  '/subscriptions/summer-sports',
  '/students',
  '/subscriptions/students',
  '/xmas',
  '/subscriptions/xmas',
  '/events',
  '/subscriptions/events',
  '/halloween',
  '/subscriptions/halloween',
  '/easter',
  '/subscriptions/easter',
  '/corporate',
  '/subscriptions/corporate',
  '/group',
  '/subscriptions/group',
  '/subscriptions/gift-redemption',
  '/subscriptions/sports/gift-redemption',
  '/subscriptions/competition',
  '/subscriptions/spring-sale',
  '/subscriptions/summer-sale',
  '/subscriptions/autumn-sale',
  '/subscriptions/winter-sale',
  '/subscriptions/upgrade',
  '/subscriptions/upgrade-offer',
  '/subscriptions/upgrade-renewal',
  '/subscriptions/upgrade-winback',
  '/subscriptions/upgrade-promotion',
  '/subscriptions/upgrade-students',
  '/upgrade',
  '/upgrade-offer',
  '/upgrade-renewal',
  '/upgrade-winback',
  '/upgrade-promotion',
  '/upgrade-students',
]

const fixedWidthSites = [
  '3addedminutes.com',
  'anguscountyworld.co.uk',
  'banburyguardian.co.uk',
  'bedfordtoday.co.uk',
  'biggleswadetoday.co.uk',
  'birminghamworld.uk',
  'bristolworld.com',
  'bucksherald.co.uk',
  'burnleyexpress.net',
  'buxtonadvertiser.co.uk',
  'chad.co.uk',
  'daventryexpress.co.uk',
  'derryjournal.com',
  'derbyshiretimes.co.uk',
  'derbyworld.co.uk',
  'dewsburyreporter.co.uk',
  'doncasterfreepress.co.uk',
  'falkirkherald.co.uk',
  'farminglife.com',
  'fifetoday.co.uk',
  'glasgowworld.com',
  'halifaxcourier.co.uk',
  'harboroughmail.co.uk',
  'harrogateadvertiser.co.uk',
  'hartlepoolmail.co.uk',
  'hemeltoday.co.uk',
  'hucknalldispatch.co.uk',
  'lancasterguardian.co.uk',
  'leightonbuzzardonline.co.uk',
  'lep.co.uk',
  'lincolnshireworld.com',
  'liverpoolworld.uk',
  'londonworld.com',
  'lutontoday.co.uk',
  'manchesterworld.uk',
  'meltontimes.co.uk',
  'miltonkeynes.co.uk',
  'nationalworld.com',
  'newcastleworld.com',
  'newryreporter.com',
  'newsletter.co.uk',
  'northamptonchron.co.uk',
  'northantstelegraph.co.uk',
  'northernirelandworld.com',
  'northumberlandgazette.co.uk',
  'nottinghamworld.com',
  'peterboroughtoday.co.uk',
  'portsmouth.co.uk',
  'rotherhamadvertiser.co.uk',
  'scotsman.com',
  'shieldsgazette.com',
  'stornowaygazette.co.uk',
  'sunderlandecho.com',
  'surreyworld.co.uk',
  'thescarboroughnews.co.uk',
  'thesouthernreporter.co.uk',
  'totallysnookered.com',
  'wakefieldexpress.co.uk',
  'warwickshireworld.com',
  'worksopguardian.co.uk',
  'yorkshirepost.co.uk',
]

// Formerly xTemplateSites
const merriweatherFontSites = [
  '3addedminutes.com',
  'anguscountyworld.co.uk',
  'banburyguardian.co.uk',
  'bedfordtoday.co.uk',
  'biggleswadetoday.co.uk',
  'birminghamworld.uk',
  'blackpoolgazette.co.uk', // not in fixedWidthSites
  'bristolworld.com',
  'bucksherald.co.uk',
  'burnleyexpress.net',
  'buxtonadvertiser.co.uk',
  'chad.co.uk',
  'daventryexpress.co.uk',
  'derbyshiretimes.co.uk',
  'derbyworld.co.uk',
  'derryjournal.com',
  'dewsburyreporter.co.uk',
  'doncasterfreepress.co.uk',
  'edinburghnews.scotsman.com',
  'falkirkherald.co.uk',
  'farminglife.com',
  'fifetoday.co.uk',
  'glasgowworld.com',
  'halifaxcourier.co.uk',
  'harboroughmail.co.uk',
  'harrogateadvertiser.co.uk',
  'hartlepoolmail.co.uk',
  'hemeltoday.co.uk',
  'hucknalldispatch.co.uk',
  'lancasterguardian.co.uk',
  'leightonbuzzardonline.co.uk',
  'lep.co.uk',
  'lincolnshireworld.com', // not in fixedWidthSites
  'liverpoolworld.uk',
  'londonworld.com',
  'lutontoday.co.uk',
  'manchesterworld.uk',
  'meltontimes.co.uk',
  'miltonkeynes.co.uk',
  'nationalworld.com',
  'newcastleworld.com',
  'newryreporter.com',
  'newsletter.co.uk',
  'northamptonchron.co.uk',
  'northantstelegraph.co.uk',
  'northernirelandworld.com', // not in fixedWidthSites
  'northumberlandgazette.co.uk',
  'nottinghamworld.com',
  'peterboroughtoday.co.uk',
  'portsmouth.co.uk',
  'rotherhamadvertiser.co.uk',
  'scotsman.com',
  'shieldsgazette.com',
  'stornowaygazette.co.uk',
  'sunderlandecho.com',
  'surreyworld.co.uk',
  'sussexexpress.co.uk', // not in fixedWidthSites
  'thescarboroughnews.co.uk',
  'thesouthernreporter.co.uk',
  'thestar.co.uk', // not in fixedWidthSites
  'totallysnookered.com',
  'wakefieldexpress.co.uk',
  'walesworld.com', //
  'warwickshireworld.com', // not in fixedWidthSites
  'wigantoday.net', // not in fixedWidthSites
  'worksopguardian.co.uk',
  'yorkshireeveningpost.co.uk', // not in fixedWidthSites
  'yorkshirepost.co.uk',
]

const increasedWidthSites = ['bristolworld.com', 'glasgowworld.com']

const simplifiedNewsletterSites = [
  'scotsman.com',
  'nationalworld.com',
  'newsletter.co.uk',
  'yorkshirepost.co.uk',
]

const todaysDateSites = ['nationalworld.com']

const trustedCopySites = ['scotsman.com']

const extendedMidTaboolaLongArticleDefaultPosition = ['scotsman.com']

const communityGuidelinesSites = [
  '3addedminutes.com',
  'anguscountyworld.co.uk',
  'banburyguardian.co.uk',
  'bedfordtoday.co.uk',
  'biggleswadetoday.co.uk',
  'birminghamworld.uk',
  'blackpoolgazette.co.uk',
  'bristolworld.com',
  'burnleyexpress.net',
  'bucksherald.co.uk',
  'buxtonadvertiser.co.uk',
  'daventryexpress.co.uk',
  'derbyworld.co.uk',
  'chad.co.uk',
  'derryjournal.com',
  'dewsburyreporter.co.uk',
  'derbyshiretimes.co.uk',
  'derbyworld.co.uk',
  'derryjournal.com',
  'doncasterfreepress.co.uk',
  'edinburghnews.scotsman.com',
  'falkirkherald.co.uk',
  'farminglife.com',
  'fifetoday.co.uk',
  'glasgowworld.com',
  'halifaxcourier.co.uk',
  'harboroughmail.co.uk',
  'hartlepoolmail.co.uk',
  'hemeltoday.co.uk',
  'hucknalldispatch.co.uk',
  'harrogateadvertiser.co.uk',
  'lancasterguardian.co.uk',
  'leightonbuzzardonline.co.uk',
  'lep.co.uk',
  'lincolnshireworld.com',
  'liverpoolworld.uk',
  'londonworld.com',
  'lutontoday.co.uk',
  'manchesterworld.uk',
  'meltontimes.co.uk',
  'miltonkeynes.co.uk',
  'nationalworld.com',
  'newcastleworld.com',
  'newryreporter.com',
  'newsletter.co.uk',
  'newryreporter.com',
  'northamptonchron.co.uk',
  'northantstelegraph.co.uk',
  'northernirelandworld.com',
  'nottinghamworld.com',
  'northumberlandgazette.co.uk',
  'peterboroughtoday.co.uk',
  'portsmouth.co.uk',
  'rotherhamadvertiser.co.uk',
  'shieldsgazette.com',
  'stornowaygazette.co.uk',
  'sunderlandecho.com',
  'surreyworld.co.uk',
  'sussexexpress.co.uk',
  'thescarboroughnews.co.uk',
  'thesouthernreporter.co.uk',
  'thestar.co.uk',
  'totallysnookered.com',
  'warwickshireworld.com',
  'wakefieldexpress.co.uk',
  'wigantoday.net',
  'worksopguardian.co.uk',
  'yorkshireeveningpost.co.uk',
  'yorkshirepost.co.uk',
]

const worldSites = [
  '3addedminutes.com',
  'anguscountyworld.co.uk',
  'birminghamworld.uk',
  'bristolworld.com',
  'derbyworld.co.uk',
  'glasgowworld.com',
  'lincolnshireworld.com',
  'liverpoolworld.uk',
  'londonworld.com',
  'manchesterworld.uk',
  'nationalworld.com',
  'newcastleworld.com',
  'northernirelandworld.com',
  'nottinghamworld.com',
  'sussexexpress.co.uk',
  'totallysnookered.com',
  'walesworld.com',
  'warwickshireworld.com',
  'newryreporter.com',
  'surreyworld.co.uk',
]

const weatherWidgetSites = [
  'birminghamworld.uk',
  'bristolworld.com',
  'derbyworld.co.uk',
  'glasgowworld.com',
  'liverpoolworld.uk',
  'londonworld.com',
  'manchesterworld.uk',
  'newcastleworld.com',
  'nottinghamworld.com',
  'derbyworld.co.uk',
  'rotherhamadvertiser.co.uk',
  'walesworld.com',
  'warwickshireworld.com',
]

const personalGreetingSites = ['scotsman.com', 'newsletter.co.uk']

const upNextSites = [
  '3addedminutes.com',
  'nationalworld.com',
  'totallysnookered.com',
]

const simplifiedHeaderSites = [
  'nationalworld.com',
  'newsletter.co.uk',
  'scotsman.com',
  'yorkshirepost.co.uk',
]

const widerArticleSites = ['glasgowworld.com']

const dropCapSites = ['scotsman.com']

const papUpdatedTemplateSites = [
  'blackpoolgazette.co.uk',
  'yorkshireeveningpost.co.uk',
]

const longPagePhotoArticleSites = [
  'blackpoolgazette.co.uk',
  'yorkshireeveningpost.co.uk',
]

const premiumArticleLayoutSites = [
  'scotsman.com',
  'yorkshirepost.co.uk',
  'newsletter.co.uk',
]

const customColorLogicSites = ['nationalworld.com', 'scotsman.com']

const ViafouraCarouselSites = [
  '3addedminutes.com',
  'anguscountyworld.co.uk',
  'banburyguardian.co.uk',
  'bedfordtoday.co.uk',
  'biggleswadetoday.co.uk',
  'birminghamworld.uk',
  'blackpoolgazette.co.uk',
  'bristolworld.com',
  'burnleyexpress.net',
  'bucksherald.co.uk',
  'buxtonadvertiser.co.uk',
  'daventryexpress.co.uk',
  'derbyworld.co.uk',
  'chad.co.uk',
  'derryjournal.com',
  'dewsburyreporter.co.uk',
  'derbyshiretimes.co.uk',
  'derbyworld.co.uk',
  'derryjournal.com',
  'doncasterfreepress.co.uk',
  'edinburghnews.scotsman.com',
  'falkirkherald.co.uk',
  'fifetoday.co.uk',
  'glasgowworld.com',
  'halifaxcourier.co.uk',
  'harboroughmail.co.uk',
  'hartlepoolmail.co.uk',
  'hemeltoday.co.uk',
  'hucknalldispatch.co.uk',
  'harrogateadvertiser.co.uk',
  'lancasterguardian.co.uk',
  'leightonbuzzardonline.co.uk',
  'lep.co.uk',
  'lincolnshireworld.com',
  'liverpoolworld.uk',
  'londonworld.com',
  'lutontoday.co.uk',
  'manchesterworld.uk',
  'meltontimes.co.uk',
  'miltonkeynes.co.uk',
  'nationalworld.com',
  'newcastleworld.com',
  'newryreporter.com',
  'newsletter.co.uk',
  'newryreporter.com',
  'northamptonchron.co.uk',
  'northantstelegraph.co.uk',
  'northernirelandworld.com',
  'nottinghamworld.com',
  'northumberlandgazette.co.uk',
  'peterboroughtoday.co.uk',
  'portsmouth.co.uk',
  'rotherhamadvertiser.co.uk',
  'shieldsgazette.com',
  'stornowaygazette.co.uk',
  'sunderlandecho.com',
  'surreyworld.co.uk',
  'sussexexpress.co.uk',
  'thescarboroughnews.co.uk',
  'thesouthernreporter.co.uk',
  'thestar.co.uk',
  'totallysnookered.com',
  'warwickshireworld.com',
  'wakefieldexpress.co.uk',
  'wigantoday.net',
  'worksopguardian.co.uk',
  'yorkshireeveningpost.co.uk',
  'yorkshirepost.co.uk',
]

const customerServiceData = {
  customerServiceOpeningTimes: '(Mon-Fri 9.30am-4:30pm)',
  customerServicePhone: '0330-403-0066',
  customerServiceEmail: 'customer.services@nationalworld.com',
  customerServiceAddress: {
    line1: 'National World Publishing Ltd',
    street1: 'Suite E3 Josephs Well',
    street2: 'Hanover Walk',
    city: 'Leeds',
    postCode: 'LS3 1AB',
  },
}

const subscriptionData = {
  printNewspaperOnly: {
    question: 'Do you have an option to subscribe to the print newspaper only?',
    digitalApp:
      'We do not currently offer a print-only subscription package. Our Print &amp; Digital&#43; package gives you access to the website and our app for free. We publish exclusive online-only content, so we recommend that option.',
    subscription:
      'We do not currently offer a print-only subscription package. Our Print &amp; Digital package gives you access to the website. We publish exclusive online-only content, so we recommend that option.',
    adLite:
      'Yes we do, please view ‘Other Subscription Options’ on our <a href="/subscriptions">subscriptions</a> page.',
  },
  switchSubscription: {
    question: 'Can I switch my subscription package after purchase?',
    digital: `Digital subscribers can upgrade to a Digital&#43; subscription in their account. Similarly, monthly subscribers can upgrade to an Annual package too. If you wish to downgrade your subscription package or to include Print, you will need to cancel your existing package (clause 7 of our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a>), and take out a new subscription on the package that you prefer. If you have further questions about this, please email our Customer Support Team at <a href="mailto:${customerServiceData.customerServiceEmail}"> ${customerServiceData.customerServiceEmail}</a> or call on ${customerServiceData.customerServicePhone} ${customerServiceData.customerServiceOpeningTimes}.`,
    app: `Digital subscribers can upgrade to a Digital&#43; subscription in their account. Similarly, monthly subscribers can upgrade to an Annual package too. If you wish to downgrade your subscription package or to include Print, you will need to cancel your existing package (clause 7 of our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a>), and take out a new subscription on the package that you prefer. If you have further questions about this, please email our Customer Support Team at <a href="mailto:{email}">{email}</a> or call on ${customerServiceData.customerServicePhone} ${customerServiceData.customerServiceOpeningTimes}`,
    subscription: `Digital Monthly subscribers can upgrade to an Annual package in their account. If you wish to downgrade your subscription package or to include Print, you will need to cancel your existing package (clause 7 of our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a>), and take out a new subscription on the package that you prefer. If you have further questions about this, please email our Customer Support Team at <a href="mailto:${customerServiceData.customerServiceEmail}">${customerServiceData.customerServiceEmail}</a> or call on ${customerServiceData.customerServicePhone} ${customerServiceData.customerServiceOpeningTimes}.`,
  },
  appPurchase: {
    question: 'Can I purchase the app by itself?',
    digitalApp:
      'The app is available as a standalone production in the Apple App Store or Google Play store. However, our Digital&#43; package offers greater value for money than the app alone, so we recommend that option.',
  },
  multiPlatformBundle: {
    question:
      'I already have a print/app/digital subscription - how do I switch to one of the multi-platform bundles?',
    digitalApp: `If you wish to switch to a new package, you will need to cancel your existing package (clause 7 of our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a>), and take out a new subscription on the package that you prefer. If you have further questions about this, please email our Customer Support Team at <a href="mailto:${customerServiceData.customerServiceEmail}">${customerServiceData.customerServiceEmail}</a> or call on  ${customerServiceData.customerServicePhone} ${customerServiceData.customerServiceOpeningTimes}.`,
  },
  contentSubscription: {
    question: 'What do I get for my subscription?',
    digital:
      'A Digital subscription gives you 70% fewer ads and subscriber-only content such as online puzzles and Premium articles. Additionally, Digital&#43; subscribers receive free and unlimited access to the mobile app. Subscribers to the Print &amp; Digital&#43; package get both website and app access, as well as the physical print paper.',
    app: 'A Digital subscription gives you unlimited access to {title}&rsquo;s online content, 70% fewer ads and subscriber-only content such as online puzzles and Premium articles. Additionally, Digital&#43; subscribers receive free and unlimited access to the mobile app. Subscribers to the Print &amp; Digital&#43; package get both website and app access, as well as the physical print paper.',
    subscription:
      'A Digital subscription gives you unlimited access to {title}&rsquo;s online content, 70% fewer ads and subscriber-only content such as online puzzles and Premium articles. Subscribers to the Print &amp; Digital package get website access, as well as the physical print paper.',
  },
  groupSubscriptions: {
    question: 'Do you offer group subscriptions for multiple users?',
    subscription: `Yes, our corporate package is a group subscription that enables a business or institution to purchase licenses for up to 100,000 users (site licenses). For more information, contact Customer Services on <a href="mailto:${customerServiceData.customerServiceEmail}"> ${customerServiceData.customerServiceEmail}</a> or call on ${customerServiceData.customerServicePhone} ${customerServiceData.customerServiceOpeningTimes}.`,
    app: `Yes, our corporate package is a group subscription that enables a business or institution to purchase licenses for up to 100,000 users (site licenses). For more information, contact Customer Services on <a href="mailto:{email}">{email}</a> or call on ${customerServiceData.customerServicePhone} ${customerServiceData.customerServiceOpeningTimes}.`,
  },
  renewal: {
    question:
      'Does my subscription renew automatically, or does it require my input?',
    digitalApp:
      'No. If you have chosen a monthly subscription, your subscription will continue automatically on a monthly basis without you having to take any steps. If you have chosen an annual subscription, your subscription will automatically continue for a further 12 month period at the end of your annual subscription without you having to take any steps. Please see clauses 7.2 and 7.3 of our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a> in terms of cancelling your subscription.',
    adLite:
      'If you have chosen a monthly subscription, your subscription will continue automatically on a monthly basis without you having to take any steps. If you have chosen an annual subscription, your subscription will automatically continue for a further 12 month period at the end of your annual subscription without you having to take any steps. Please see clauses 7.2 and 7.3 of our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a> in terms of cancelling your subscription.',
  },
  contentAccess: {
    question: 'Which content can I still access if I don&rsquo;t subscribe?',
    digital:
      'Non-registered users are entitled to unlimited access to all non-Premium articles and registered users are entitled to 1 Premium article per month, after which subscription is required to view additional Premium content. Your entitlement resets each month. In addition, we publish Premium content, which is only accessible to subscribers.',
    subscription:
      'Non-registered users are entitled to 2 free articles per week, and registered users are entitled to 5, after which subscription is required to view additional content. Your entitlement resets each week. In addition, we publish Premium content, which is only accessible to subscribers.',
    app: 'Subscribers have unlimited access to all our content, whereas non-subscribers will face restrictions on the type and volume of content they are able to read without a subscription.',
    adLite:
      'You will still be able to access all of our content, even as a non-subscriber, but you will not have access to the ad-lite version of the website.',
  },
  payment: {
    question: 'How does continuous payment work?',
    digital:
      'Direct debit, credit and debit card payments for digital subscriptions are made on either a monthly or annual basis. You are not able to terminate your subscription during the minimum terms, although you do have a right to change your mind about your subscription within the first 14 days of your order, please see clause 7.2 of our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a>.',
  },
  creditCard: {
    question: 'What happens if my credit card or debit card details change?',
    digital:
      'If you pay by credit or debit card, you will need to update those details in your account. Log in to access your account and update your details. If you do not update your details and we do not receive your next scheduled payment your subscription may be terminated in accordance with our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a>.',
  },
  securePayment: {
    question: 'Is the online payment secure?',
    digital:
      'We use SSL encryption software to keep the details of your order and payment information secure.',
  },
  order: {
    question: 'How do you acknowledge receipt of my order?',
    digital: 'Proof of purchase will be sent to your registered email address.',
  },
  browserSupport: {
    question: 'What browsers do your websites support?',
    digital:
      'Our websites are designed to support the current versions of widely used web browsers, including Microsoft Edge, Google Chrome, Apple Safari and Mozilla Firefox.',
  },
  login: {
    question: 'When will I get my subscription login details?',
    digital:
      'Your login details are the same details you gave when you registered with the {title} website.',
  },
  holiday: {
    question: 'What happens if I go on holiday?',
    digital:
      'Your digital subscription is continuous. You can access your content 24/7, 365 days a year, anywhere you have internet access.',
  },
  personalData: {
    question: 'What do you do with my personal information?',
    digital:
      'We will use your personal information in accordance with our Privacy Notice, please refer to the National World Publishing Ltd <a href="/privacy/" target="_blank" rel="noopener noreferrer">Privacy Notice</a>',
  },
  cancelSubscription: {
    question: 'What happens if I change my mind?',
    digital:
      'You may cancel your order within 14 days of subscribing and you shall receive a refund of any payment that you have paid to us provided that we shall be entitled to retain payment for any of your subscription you have already received. Unlimited access to the website and the mobile app will stop immediately. Please refer to our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a> for more information.',
    adLite:
      'You may cancel your order within 14 days of subscribing and you shall receive a refund of any payment that you have paid to us provided that we shall be entitled to retain payment for any of your subscription you have already received. Your access to the ad-lite version of our website will stop immediately. Please refer to our <a href="/subscriptions/terms-and-conditions" >Terms and Conditions</a> for more information.',
  },
  cancellationPolicy: {
    question: 'Can I cancel my digital subscription?',
    digital:
      'If you cancel within 14 days of the start of your subscription, we will refund any payment made by you.  You will not be entitled to a refund of your initial payment if you cancel after the first 14 days of the start of your subscription. From your account, you can turn off auto renew to end your subscription at the end of the billing period, and if you wish to have a refund processed please contact us using <a href="/subscriptions/help">this form</a> within the first 14 days of the start of your subscription.',
  },
  other: {
    question: 'I have a question that isn&rsquo;t listed here',
    digital: `Good news, we have humans for you to talk to! Our Customer Support team will be happy to talk you through any other questions you have. Please email them at <a href="mailto:{email}">{email}</a> or call on ${customerServiceData.customerServicePhone} ${customerServiceData.customerServiceOpeningTimes}.`,
    adLite: `Good news, we have humans for you to talk to! Our Customer Support team will be happy to talk you through any other questions you have. Please email them at <a href="mailto:${customerServiceData.customerServiceEmail}">${customerServiceData.customerServiceEmail}</a> or call on ${customerServiceData.customerServicePhone} ${customerServiceData.customerServiceOpeningTimes}.`,
  },
  introductoryOffer: {
    question: 'How does payment for an introductory offer work?',
    digital:
      'Subscribers who take up an exclusive or introductory offer will see their subscription price automatically revert to a higher price once their offer period ends.',
  },
  appDownload: {
    question: 'How do I download and use my app?',
    digitalApp:
      'The app is available for iOS and Android. Search for the app by title in the Apple App Store or Google Play store to download it for free. Log into your app using the email and password you have set for your subscription account.',
  },
}

const domainWithApp = [
  'blackpoolgazette.co.uk',
  'edinburghnews.scotsman.com',
  'lep.co.uk',
  'nationalworld.com',
  'newsletter.co.uk',
  'portsmouth.co.uk',
  'scotsman.com',
  'sunderlandecho.com',
  'thestar.co.uk',
  'wigantoday.net',
  'yorkshireeveningpost.co.uk',
  'yorkshirepost.co.uk',
]

const containedWidthTitleSlab = ['farminglife.com']

const isTVLinkDisabled = [
  '3addedminutes.com',
  'farminglife.com',
  'nationalworld.com',
  'newsletter.co.uk',
  'scotsman.com',
  'totallysnookered.com',
  'yorkshirepost.co.uk',
]

const dynamicVideoPreviewSites = ['nationalworld.com']

const domainsWithChartbeat = [
  'edinburghnews.scotsman.com',
  'yorkshirepost.co.uk',
]

const nationalWorldDomain = [
  '3addedminutes.com',
  'anguscountyworld.co.uk',
  'banburorkguardian.co.uk',
  'bedfordtoday.co.uk',
  'biggleswadetoday.co.uk',
  'birminghamworld.uk',
  'blackpoolgazette.co.uk',
  'bristolworld.com',
  'bucksherald.co.uk',
  'burnleyexpress.net',
  'buxtonadvertiser.co.uk',
  'davenportexpress.co.uk',
  'derbyshiretimes.co.uk',
  'derbyworld.co.uk',
  'derryjournal.com',
  'dewsburyreporter.co.uk',
  'doncasterfreepress.co.uk',
  'edinburghnews.scotsman.com',
  'falkirkherald.co.uk',
  'farminglife.com',
  'fifetoday.co.uk',
  'glasgowworld.com',
  'halifaxcourier.co.uk',
  'harboroughmail.co.uk',
  'harrogateadvertiser.co.uk',
  'hartlepoolmail.co.uk',
  'hemeltoday.co.uk',
  'hucknalldispatch.co.uk',
  'newsletter.co.uk',
  'peterboroughtoday.co.uk',
  'portsmouth.co.uk',
  'rotherhamadvertiser.co.uk',
  'scotsman.com',
  'shieldsgazette.com',
  'stornowaygazette.co.uk',
  'sunderlandecho.com',
  'surreyworld.co.uk',
  'sussexexpress.co.uk',
  'thescarboroughnews.co.uk',
  'thesouthernreporter.co.uk',
  'thestar.co.uk',
  'totallysnookered.com',
  'wakefieldexpress.co.uk',
  'walesworld.com',
  'warwickshireworld.com',
  'wigantoday.net',
  'worksopguardian.co.uk',
  'yorkshireeveningpost.co.uk',
  'yorkshirepost.co.uk',
]

const domainsWithCustomCTAHeader = [
  'northumberlandgazette.co.uk',
  'burnleyexpress.net',
  'lancasterguardian.co.uk',
  'blackpoolgazette.co.uk',
  'biggleswadetoday.co.uk',
]

const domainsWithNativeShare = ['nationalworld.com']

const pianoActiveChurnDomains = ['scotsman.com', 'yorkshirepost.co.uk']

const domainsWithUppercaseHeadings = ['3addedminutes.com', 'nationalworld.com']

const domainsWithMutedPlayer = [
  'blackpoolgazette.co.uk',
  'edinburghnews.scotsman.com',
  'glasgowworld.com',
  'liverpoolworld.uk',
  'nationalworld.com',
  'northantstelegraph.co.uk',
  'portsmouth.co.uk',
  'yorkshireeveningpost.co.uk',
]

export {
  adConfig,
  analytics,
  breakingNewsTickerBlacklistedDomains,
  breakingNewsTickerBlacklistedPages,
  communityGuidelinesSites,
  containedWidthTitleSlab,
  customColorLogicSites,
  customerServiceData,
  domainsWithChartbeat,
  domainsWithCustomCTAHeader,
  domainsWithMutedPlayer,
  domainsWithNativeShare,
  domainsWithUppercaseHeadings,
  domainWithApp,
  dropCapSites,
  dynamicVideoPreviewSites,
  extendedMidTaboolaLongArticleDefaultPosition,
  fixedWidthSites,
  increasedWidthSites,
  isTVLinkDisabled,
  longPagePhotoArticleSites,
  merriweatherFontSites,
  nationalWorldDomain,
  papUpdatedTemplateSites,
  personalGreetingSites,
  pianoActiveChurnDomains,
  premiumArticleLayoutSites,
  simplifiedHeaderSites,
  simplifiedNewsletterSites,
  subscriptionData,
  subscriptionPages,
  todaysDateSites,
  trustedCopySites,
  upNextSites,
  ViafouraCarouselSites,
  weatherWidgetSites,
  widerArticleSites,
  worldSites,
}
