import { initGTMScript } from '~/util/gtm'

import { initParselyAnalytics } from '~/util/parselyAnalytics'
import { initPermutiveTag } from '~/util/permutive'

import { isThirdPartyModeEnabled } from '~/util/speed-tool'

export const initialisePreConsent = (): void => {
  // Map script aliases with their corresponding functions for speed-tool test
  // when using query param, ie. ?3rd-party-mode=placeholder&gtm-mode=enabled
  const scriptAliases: Record<string, () => void> = {
    gtm: initGTMScript,
    parsely: initParselyAnalytics, // Analytics
    initPermutiveTag, // Permutive audience
  }

  const { isEnabled, enabledValues } = isThirdPartyModeEnabled()

  // Iterate over each script alias for initialisation
  Object.entries(scriptAliases).forEach(([alias, initFunction]) => {
    // Check if the script should be enabled based on query params
    const isScriptEnabled = enabledValues.includes(alias)
    // If third-party mode is enabled and the script is not enabled, skip initialisation
    if (isEnabled && !isScriptEnabled) {
      return
    }

    // Otherwise, initialise the script
    initFunction()
  })
}
