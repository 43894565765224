import striptags from 'striptags'

/** use to return an override value if valid, otherwise return default value */
export const getValueOrDefault = (defaultValue, overrideValue) =>
  typeof overrideValue === 'number' ? overrideValue : defaultValue

/** use to check if paragraph is equal or greater than 60 characters */
export const isParagraphLongEnough = (markup) =>
  markup.replace(/(<([^>]+)>)/gi, '').length >= 60

/** Function to check that all commerical ad elements are after paragraph markup items, and rearrange if not */
export const ensureCommercialElementsAreAfterParagraphs = (content) => {
  const isCommercialAdElement = (key) => {
    const commercialKeywords = ['taboola', 'adslottead', 'components']
    const lowerCaseKey = key.toLowerCase()
    return commercialKeywords.some((keyword) => lowerCaseKey.includes(keyword))
  }

  let contentArray = []
  // Initialise the index of the last paragraph markup item found in the content array
  let lastParagraphMarkupItem = -1

  content.forEach((element, index) => {
    // Check if the element is a paragraph markup item
    if (
      element.key.startsWith('markup') &&
      element.props?.data?.markup?.includes('<p>')
    ) {
      // Update the index of the last paragraph markup item found
      lastParagraphMarkupItem = index
      contentArray.push(element)
    }
    // If the content item contains 'taboola' or 'adSlotTead' or 'components' (MPU), treat it as a commercial ad element - also works for lite users
    else if (isCommercialAdElement(element.key)) {
      // If a paragraph markup item has been found previously
      if (lastParagraphMarkupItem !== -1) {
        // Insert the commercial ad element after the last paragraph markup item in the new array
        contentArray.splice(lastParagraphMarkupItem + 1, 0, element)
      }
      // If no paragraph markup item has been found yet
      else {
        contentArray.push(element)
      }
    } else {
      contentArray.push(element)
    }
  })

  return contentArray
}

/** Function to strip elements that Taboola doesn't like from the end of the content array and reorder Taboola ads if placed immediately after images */
export const stripNonTaboolaFriendlyElements = (content) => {
  // Define an array of allowed component types
  const allowedComponents = [
    'markup',
    'live-blog',
    'LiveBlog',
    'listbulleted',
    'listnumbered',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'products',
    'product-verdict',
    'newsletter-signup',
    'piano-article-paywall',
    'taboola',
    'image',
  ]

  // Headers array
  const headers = ['H2', 'H3', 'H4', 'H5', 'H6']

  // Define the elements that are allowed after headers
  const elementsAllowedAfterHeaders = ['listbulleted', 'listnumbered']

  // Helper function to check if a component key is allowed
  const _isAllowed = (componentKey) =>
    allowedComponents.some((type) => {
      const expression = new RegExp(type)
      return expression.test(componentKey)
    })

  // Helper function to calculate the character count of the post body in a live blog post
  const _blogPostBodyCharacterCount = (postJson) => {
    postJson = postJson ?? []
    return postJson.reduce((bodyCharacterCount, bodyItem) => {
      const bodyItemText = striptags(bodyItem?.data?.markup ?? '').trim()
      return bodyCharacterCount + bodyItemText.length
    }, 0)
  }

  // Helper function to calculate the total character count of a blog post (title + body)
  const _blogPostCharacterCount = ({ data, postJson } = {}) => {
    const postTitle = (data?.title ?? '').trim()
    return postTitle.length + _blogPostBodyCharacterCount(postJson)
  }

  // Helper function to calculate the total character count of a live blog (name + all posts)
  const _liveBlogCharacterCount = ({ type, data = {}, posts = [] } = {}) => {
    if (type !== 'liveblog') return 0
    const liveBlogName = (data.name ?? '').trim()
    let characterCount = liveBlogName.length
    posts.forEach((post) => {
      characterCount += _blogPostCharacterCount(post)
    })
    return characterCount
  }

  // Function to swap the Taboola ad with the image if image is directly before the ad using reduce
  const _moveTaboolaAdBeforeImage = (content) =>
    content.reduce((acc, item, index, arr) => {
      const prevItem = arr[index - 1]
      if (
        item?.key?.startsWith('taboola') &&
        prevItem?.key?.startsWith('image')
      ) {
        // Swap the image and Taboola ad positions
        acc[acc.length - 1] = item // Place Taboola where the image was
        acc.push(prevItem) // Move the image after the Taboola
      } else {
        acc.push(item) // Keep the item in its place if no swapping is needed
      }
      return acc
    }, [])

  // Initialize character total and tracking variables
  let characterTotal = 0
  let lastSignificantMarkup = null
  let hasAllowedElementAfterHeader = false

  // Process the content array in reverse order
  let filteredContent = content
    .reverse()
    .filter((item) => {
      if (!item?.key) return false // Skip items without a key

      const { key, props: { data } = {} } = item
      const markup = striptags(data?.markup ?? '').trim() // Strip HTML tags and trim whitespace from markup

      // If the total character count has reached or exceeded 200, keep all remaining items
      if (characterTotal >= 200) {
        return true
      }

      // Filter out non-allowed components
      if (!_isAllowed(key)) {
        return false
      }

      // Track the last significant markup component with non-empty content
      if (key.startsWith('markup') && markup.length > 0) {
        lastSignificantMarkup = item
      }

      // Special handling for headers
      if (
        headers.some((header) => key.startsWith(header)) &&
        markup.length > 0
      ) {
        // Check if the last significant markup or an allowed component exists before keeping the header
        if (lastSignificantMarkup || hasAllowedElementAfterHeader) {
          const lastSignificantMarkupText = striptags(
            lastSignificantMarkup?.props?.data?.markup ?? '',
          ).trim()

          // Remove the header if the last significant markup has insufficient content and there's no allowed element after the header
          if (
            lastSignificantMarkupText.length < 200 &&
            !hasAllowedElementAfterHeader
          ) {
            return false
          }
        }
      }

      // Track if there's an allowed element after a header
      if (
        elementsAllowedAfterHeaders.some((element) => key.startsWith(element))
      ) {
        hasAllowedElementAfterHeader = true
      }

      // Add the character count of the current markup to the total
      characterTotal += markup.length
      // Add the character count of the live blog, if applicable
      characterTotal += _liveBlogCharacterCount(data)

      // Keep the component
      return true
    })
    .reverse() // Reverse the filtered content back to the original order

  // Reorder Taboola ads if they appear after an image
  filteredContent = _moveTaboolaAdBeforeImage(filteredContent)

  return filteredContent
}

/** use to check if the article contains products and/or affiliate links in the content */
const affiliatePatterns = [
  /https?:\/\/go\.skimresources\.com\/?/,
  /https?:\/\/(www\.)?amazon\.?/,
  /https?:\/\/(www\.)?amzn\.?/,
  /https?:\/\/go\.linkby\.com\/?/,
]

/** Checks if an article contains affiliate links. */
export const containsAffiliateLinks = (article) => {
  return (
    article.products?.length > 0 ||
    article.body?.some(
      (item) =>
        ['listnumbered', 'listbulleted', 'markup'].includes(item.type) &&
        affiliatePatterns.some((pattern) => pattern.test(item.data?.markup)),
    )
  )
}
