const sendTracking = (interaction: 'visible' | 'click') => {
  const data = {
    event: 'widget_interaction',
    interaction: interaction,
    position: 'in article',
    widgetName: 'yourworld read cta',
    widgetType: 'promo',
  }
  window.dataLayer?.push(data)
}

const initialiseYourWorldTracking = () => {
  const bannerElement = document.getElementById('yw-banner')
  const ctaButton = document.getElementById('yw-banner-link')

  if (!bannerElement || !ctaButton) return

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          sendTracking('visible')
          observer.disconnect()
        }
      })
    },
    { threshold: 0.5 },
  )

  observer.observe(bannerElement)

  ctaButton.addEventListener('click', () => sendTracking('click'))
}

export default initialiseYourWorldTracking
