import { jsLoader } from './fileLoaders'

export const ASSERTIVE_YIELD_ENTITY_ID = 'TF274J8zxtuJmNBsh'

export const assertive = {
  debug: false,
  entityId: ASSERTIVE_YIELD_ENTITY_ID,
  analytics: {
    integrations: {
      webAnalytics: true,
    },
    logUnfilled: true,
    custom: {
      // Custom data (optional) expects null or string
      layout: null,
      userState: null,
      // Dynamically create 15 custom key-value pairs
      ...Object.fromEntries(
        Array.from({ length: 15 }, (_, i) => [`custom_${i + 1}`, null]),
      ),
    },
  },
}

export const initAssertiveYield = () => {
  // Assign assertive object to window.assertive
  window.assertive = assertive

  try {
    // Load Assertive Yield script
    jsLoader(
      [
        `https://${ASSERTIVE_YIELD_ENTITY_ID}.ay.delivery/manager/${ASSERTIVE_YIELD_ENTITY_ID}`,
      ],
      'assertiveyield',
    )
  } catch (error) {
    console.error('Error loading Assertive Yield script:', error)
  }
}
