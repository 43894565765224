import * as Throttle from 'promise-parallel-throttle'
import { initDailyMotion } from '~/util/dailymotion'
import { getGdprConsentToken } from '~/util/get-gdpr-consent-token'
import { userStatus } from '~/util/userStatus'
import { initialisePreConsent } from '~/util/pre-consent-scripts'
import { initAds } from './initAds'
import { initMantis } from '~/util/get-external-scripts'
import { initTaboolaTag } from '~/util/taboola'
import { initChartbeat } from '~/util/initChartbeat'
import { initialiseMainModules } from './main'
import { sourcePoint_v2_0 } from '~/util/sourcePoint'
import { initAssertiveYield } from '~/util/assertive-yield'

const pageType: string = window?.JSGlobals?.pageType ?? ''

const isTaboolaPage: boolean =
  pageType === 'article' ||
  pageType === 'video article' ||
  pageType === 'live blog article' ||
  pageType === 'photo article'

initialisePreConsent()

window.JSGlobals = window.JSGlobals ?? { mode: 'development' }

const initV2: boolean | null = window.JSGlobals?.newInit

console.log(`init v2 : ${initV2}`)

let hasUserConsented: boolean | null = null

// Function to wait for the consent decision
const waitForConsent = () => {
  return new Promise((resolve) => {
    const consentKeyPrefix = '_sp_user_consent_'

    const checkConsent = () => {
      const consentKey = Object.keys(localStorage).find((key) =>
        key.startsWith(consentKeyPrefix),
      )

      if (consentKey) {
        const spData = localStorage.getItem(consentKey)
        const gdprData = spData ? JSON.parse(spData) : null
        if (gdprData?.gdpr?.consentStatus?.hasConsentData === true) {
          hasUserConsented = !gdprData?.gdpr?.consentStatus?.rejectedLI
          resolve(hasUserConsented)
        } else {
          setTimeout(checkConsent, 50)
        }
      } else {
        setTimeout(checkConsent, 50)
      }
    }
    checkConsent()
  })
}

const initialisers = [
  // First batch: Run these immediately
  {
    getGdprConsentToken,
  },
  // Second batch: Wait for consent decision first
  {
    initGPT: async () => {
      await waitForConsent()
      if (hasUserConsented) {
        initAds()
      }
    },
  },
  // Third batch: Runs after the consent decision (regardless of answer)
  {
    initDailyMotion: async () => {
      await initDailyMotion()
    },
    initMantis,
    userStatus,
    initChartbeat,
    initTaboolaTag: () => {
      if (isTaboolaPage) {
        initTaboolaTag(pageType.includes('photo') ? 'photo' : 'article')
      }
    },
  },
]

const initialisersV1 = [
  // Load consent manager
  {
    consent: {
      method: async () => {
        try {
          console.log(`starting consent block at ${performance.now()}ms`)
          await sourcePoint_v2_0() // SourcePoint CMP
        } catch (e) {
          console.error('Error with SourcePoint Consent manager')
        } finally {
          userStatus() // Piano + Axate + Google Extended Access
          console.log(`finished consent block at ${performance.now()}ms`)
        }
      },
      // Audience Data
      dependents: {
        initAssertiveYield, // Assertive Yield ads script
        getGdprConsentToken, // Generate GDPR Consent Token and set Permutive consent
      },
    },
  },
  {
    initGPTorOptOut: () => {
      // GPT or Opt Out
      // Check for user consent value in local storage
      const consentKeyPrefix = '_sp_user_consent_'

      // Check SourcePoint consent data
      const getSpData = (): string | null => {
        const consentKey = Object.keys(localStorage).find((key) =>
          key.startsWith(consentKeyPrefix),
        )
        return consentKey ? localStorage.getItem(consentKey) : null
      }

      const parseGdprData = (spData: string | null) =>
        spData ? JSON.parse(spData) : null

      const hasConsent = (gdprData: any): boolean =>
        !gdprData?.gdpr?.consentStatus?.rejectedLI

      const spData = getSpData()
      const gdprData = parseGdprData(spData)
      const hasUserConsented = hasConsent(gdprData)

      // User has consented to third party cookies
      if (hasUserConsented) {
        initAds()
      }
    },
    initDailyMotion: async () => {
      await initDailyMotion() // DailyMotion video player
    },
    initMantis, // Mantis initialization
  },
  {
    initTaboolaTag: () => {
      if (isTaboolaPage) {
        if (
          pageType === 'article' ||
          pageType === 'live blog article' ||
          pageType === 'video article'
        ) {
          initTaboolaTag('article')
        } else if (pageType === 'photo article') {
          initTaboolaTag('photo')
        }
      }
    },
  },
]

const initPromises = (inits: any) =>
  Object.keys(inits).map((key) => async () => {
    const initFunc = inits[key]
    if (!initFunc) {
      return
    }
    try {
      if (initFunc.method) {
        await initFunc.method()
        if (initFunc.dependents) {
          await Throttle.all(initPromises(initFunc.dependents), {
            failFast: false,
          })
        }
      } else {
        await initFunc()
      }
    } catch (e) {
      console.warn(`Error caught in ${key}()`)
      console.error(e)
    }
  })

// Function to initialize modules in correct order
const initialiseModules = async () => {
  for (let i = 0; i < initialisers.length; i++) {
    await Throttle.all(initPromises(initialisers[i]), { failFast: false })
  }
}

// Function to initialize modules in correct order --- use if server side hasn't updated to use inline CMP and AY
const initialiseV1 = async () => {
  for (let i = 0; i < initialisersV1.length; i++) {
    await Throttle.all(initPromises(initialisersV1[i]), { failFast: false })
  }
}

// Start the initialization process -- temp hack to get around server side not updating automatically to use inline CMP and AY
if (initV2) {
  initialiseModules().then(() => {
    console.log(`Initialiser V2 modules initialised ${performance.now()}`)
    initialiseMainModules().then(() => {
      console.log(`Main modules initialised ${performance.now()}`)
    })
  })
} else {
  //@todo remove once cache clear is complete
  initialiseV1().then(() => {
    console.log(`Initialiser V1 modules initialised ${performance.now()}`)
    initialiseMainModules().then(() => {
      console.log(`Main modules initialised ${performance.now()}`)
    })
  })
}
